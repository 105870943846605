import { Injectable } from '@angular/core';

declare let fbq: any; // Declaramos fbq para que TypeScript no marque errores.

@Injectable()
export class FacebookPixelService {
	/**
	 * Envía un evento estándar o personalizado a Facebook Pixel.
	 * @param eventName El nombre del evento (por ejemplo, 'Purchase', 'Lead', 'CustomEvent').
	 * @param parameters Los parámetros personalizados que deseas enviar junto con el evento.
	 */
	trackEvent(eventName: string, parameters: Record<string, any> = {}): void {
		try {
			if (typeof fbq === 'undefined') {
				console.warn('Facebook Pixel no está inicializado.');
				return;
			}

			fbq('trackCustom', eventName, parameters);
			console.log(`Evento enviado a Facebook Pixel: ${eventName}`, parameters);
		} catch (error) {
			console.error('Error al enviar el evento a Facebook Pixel:', error);
		}
	}
}

